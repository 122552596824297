'use client'

import { useEffect } from 'react'
import AOS from 'aos'
import { Montserrat } from 'next/font/google'
import useScroll from '@/src/hooks/useScroll'
import { IoMdArrowRoundUp } from 'react-icons/io'

import './globals.css'
import 'aos/dist/aos.css'
import Header from '@/src/components/header'
import Footer from '@/src/components/footer'
import CookieConsent from '@/src/components/cookies'

const montserrat = Montserrat({
  subsets: ['latin'],
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  style: ['normal', 'italic']
})

export default function PageLayout({
  children
}: Readonly<{
  children: React.ReactNode
}>) {
  const scrolled = useScroll(50)

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <body className={`bg-neutral overflow-x-hidden ${montserrat.className}`}>
      <div className="flex flex-col justify-between w-full min-h-screen">
        <div>
          <Header />
          <div>{children}</div>
          {scrolled && (
            <div
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              className="flex flex-col fixed bottom-8 right-8 border border-stroke bg-white/80 rounded-t-full !rounded-b-full cursor-pointer shadow-md scale-75 md:scale-100 z-[999]"
            >
              <div className="-mb-5 p-4">
                <IoMdArrowRoundUp className="h-6 w-6" />
              </div>
              <div className="p-4">
                <span className="text-dark text-md">Up</span>
              </div>
            </div>
          )}
        </div>
        <CookieConsent />
        <Footer />
      </div>
    </body>
  )
}
