import React from 'react'

import useScroll from '@/src/hooks/useScroll'
import Navigation from '../navigation'
import { usePathname } from 'next/navigation'

const Header = () => {
  const scrolled = useScroll(100)
  const pathname = usePathname()

  const isHomePage = pathname === '/'

  return (
    <>
      <header
        className={`absolute top-0 left-0 right-0 z-[999] p-0 md:py-2.5 flex justify-center ${
          isHomePage ? '' : 'bg-secondary'
        }`}
      >
        <div className="rounded-xl p-2 mx-auto box-border flex justify-between items-center mt-3 w-full">
          <Navigation scrolled={scrolled} />
        </div>
      </header>
    </>
  )
}

export default Header
