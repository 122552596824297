import React, { useEffect, useState } from 'react'

const isBrowser = typeof window !== 'undefined'

function useHasMounted(): boolean {
  const [hasMounted, setHasMounted] = useState<boolean>(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  return hasMounted
}

function useStickyState<T>(
  defaultValue: T,
  key: string
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    if (isBrowser) {
      const stickyValue = window.localStorage.getItem(key)
      return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue
    } else {
      return defaultValue
    }
  })

  useEffect(() => {
    if (isBrowser) {
      window.localStorage.setItem(key, JSON.stringify(value))
    }
  }, [key, value])

  return [value, setValue]
}

const CookieConsent: React.FC = () => {
  const hasMounted = useHasMounted()

  const [bannerHidden, setBannerHidden] = useStickyState<boolean>(false, 'consentCookieHidden')

  const EnableAnalytics = () => {
    document.cookie = 'gdpr-google-tagmanager=true; path=/; max-age=31536000' // 1 year expiry
    setBannerHidden(true)
  }

  const DeclineAnalytics = () => {
    setBannerHidden(true)
  }

  if (!hasMounted) {
    return null
  }

  return (
    <>
      {!bannerHidden && (
        <div className="fixed bg-white p-6 border border-secondary left-0 bottom-0 md:left-4 md:bottom-4 w-full md:w-[340px] rounded-lg z-[999]">
          <span className="text-sm">
            We use cookies to improve user experience and analyze website traffic. By clicking
            &quot;Accept&quot;, you agree to our{' '}
            <a href="/cookie-policy" target="_blank" className="text-secondary underline">
              website&apos;s cookie
            </a>{' '}
            use.
          </span>
          <div className="flex flex-row items-center space-x-4 my-2">
            <button
              className="px-2 py-1 bg-phoenix-orange text-neutral w-full cursor-pointer"
              onClick={EnableAnalytics}
            >
              Accept
            </button>
            <button
              className="px-2 py-1 text-neutral w-full cursor-pointer"
              onClick={DeclineAnalytics}
            >
              <span>Decline</span>
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default CookieConsent
