import React from 'react'

import { MdLocationPin, MdMail, MdOutlinePhoneAndroid } from 'react-icons/md'

const Footer = () => {
  const year = new Date().getFullYear()

  const navLinks = [
    {
      label: 'Privacy Policy',
      url: '/policy'
    },
    {
      label: 'Cookie Policy',
      url: '/cookie-policy'
    }
  ]

  const contactData = [
    {
      icon: MdMail,
      href: 'mailto:sales@gambly.ai',
      data: 'sales@gambly.ai'
    }
  ]

  return (
    <footer className="bg-secondary py-12 border-t border-white/10">
      <div className="md:w-2/3 w-full mx-auto flex flex-col space-y-8 md:space-y-0 md:flex-row justify-between">
        <div className="flex flex-col items-center md:items-start space-y-2 text-white">
          <span>Gambly.AI</span>
          {navLinks?.map((link, index) => (
            <a key={index} href={link.url} className="md:mr-4 text-white/60 text-xs">
              {link.label}
            </a>
          ))}
        </div>
        <div className="flex flex-col items-center md:items-start space-y-2 text-white">
          {contactData?.map((contact, index) => {
            const IconComponent = contact.icon
            return (
              <div key={index} className="flex items-center space-x-2">
                <IconComponent />
                <a href={contact.href} className="text-white/60 text-xs">
                  {contact.data}
                </a>
              </div>
            )
          })}
        </div>
        <div className="flex flex-col justify-end items-center md:items-end">
          <p className="footer-copy text-left text-white/60 text-xs">
            All rights reserved. &copy; {year} <b>Gambly.AI</b>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
