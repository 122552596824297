import React, { useEffect, useState } from 'react'

import { useParams, usePathname, useRouter } from 'next/navigation'
import { scrollToElementWithOffset } from '@/src/helpers'
import BurgerButton from '../burger-button'
import Image from 'next/image'

import HeaderLogo from '@/public/logo.png'
import { GoArrowRight } from 'react-icons/go'

interface Props {
  scrolled?: boolean
}

const Navigation: React.FC<Props> = ({ scrolled }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const year = new Date().getFullYear()

  const pathname = usePathname()
  const params = useParams()
  const router = useRouter()

  const documentExists = typeof document !== 'undefined' && typeof window !== 'undefined'

  const isHomePage = pathname === '/'

  const landing = documentExists && document.getElementsByClassName('landing')[0]
  const products = documentExists && document.getElementsByClassName('products')[0]
  const solutions = documentExists && document.getElementsByClassName('solutions')[0]
  const contact = documentExists && document.getElementsByClassName('contact-us')[0]
  const aboutUs = documentExists && document.getElementsByClassName('about')[0]

  const handleMobileLink = (link: any) => {
    scrollToElementWithOffset(link)
    setIsOpen(false)
  }

  const navLinks = [
    {
      label: 'Solutions',
      onClick: () => (isHomePage ? handleMobileLink(products) : router.push('/#solutions'))
    },
    {
      label: 'Results',
      onClick: () => (isHomePage ? handleMobileLink(solutions) : router.push('/#results'))
    },
    {
      label: 'About',
      onClick: () => (isHomePage ? handleMobileLink(aboutUs) : router.push('/#about'))
    }
  ]

  useEffect(() => {
    const hash = location.hash

    if (hash === '#solutions') {
      const clientLink = document.getElementsByClassName('products')[0]
      handleMobileLink(clientLink)
    } else if (hash === '#contact') {
      const contactLink = document.getElementsByClassName('contact-us')[0]
      handleMobileLink(contactLink)
    } else if (hash === '#results') {
      const resultsLink = document.getElementsByClassName('solutions')[0]
      handleMobileLink(resultsLink)
    } else if (hash === '#about') {
      const aboutUsLink = document.getElementsByClassName('about')[0]
      handleMobileLink(aboutUsLink)
    }
  }, [pathname, params])

  return (
    <div className="w-full">
      {isOpen && (
        <div
          // data-aos="fade-in"
          className="fixed top-0 left-0 bottom-0 right-0 transition-all duration-500 cursor-pointer backdrop-blur-xs"
          onClick={() => setIsOpen(false)}
        />
      )}
      <div
        // data-aos="fade-right"
        className={`fixed bg-secondary rounded-lg flex w-1/2 mx-auto flex-col justify-between h-fit top-20 left-0 right-0 bottom-0 bg-neutral p-6 ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        <div>
          {navLinks?.map((link, index) => (
            <div onClick={link.onClick} key={index} className="relative group">
              <button className="flex flex-row items-center space-x-2 my-2 py-2 text-white active w-full justify-center">
                <span>{link.label}</span>
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full flex flex-row justify-around items-center">
        <a className="flex items-center space-x-2" href={isHomePage ? '#' : '/'}>
          <Image
            src={HeaderLogo}
            onClick={() => (isHomePage ? handleMobileLink(landing) : router.push('/'))}
            alt="Logo"
            className={`w-auto h-20 transition-all duration-500`}
          />
        </a>
        <ul className="hidden flex-row items-center md:flex" id="pc-header-nav">
          {navLinks?.map((link, index) => (
            <li
              className="text-sm p-4 cursor-pointer uppercase text-white"
              onClick={link.onClick}
              key={index}
            >
              {link.label}
            </li>
          ))}
        </ul>
        <div className="flex flex-row items-center">
          <button
            className={`text-neutral p-2 h-fit rounded-lg flex flex-row items-center cursor-pointer transition-all duration-200 hover:scale-105 ${
              scrolled ? 'w-fit' : 'w-10 h-10 md:w-fit md:h-fit'
            }`}
            onClick={() => (isHomePage ? handleMobileLink(contact) : router.push('/#contact'))}
          >
            <div className="block md:hidden">
              <div data-aos="fade-right">
                <span data-aos="fade-right" className="hidden md:block">
                  Contact us
                </span>
              </div>
            </div>
            <div className="hidden md:flex flex-row items-center space-x-1 bg-accent p-3 text-white rounded-lg uppercase text-[12px] font-medium">
              <span className="hidden lg:block">Contact us</span>
              <GoArrowRight className="w-5 h-5" />
            </div>
          </button>
          <BurgerButton isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
    </div>
  )
}

export default Navigation
